import React, { useState } from 'react';
import { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next';
import Link from 'next/link';
import { getToken } from 'next-auth/jwt';
import { AuthLayout as Layout } from 'components/layouts'
import { Button, Text } from '@skip-scanner/ui'

export const getServerSideProps:GetServerSideProps = async (context: GetServerSidePropsContext) => {

  const JWT = await getToken({ req: context.req });

  if(JWT) {
    return {
      redirect: { destination: '/', permanent: false }
    }
  }

  return {
    props: {},
  }
}

const Index:NextPage = (props) => {

  return (
    <Layout title='Authenticatie'>

      {/* Title */}
      <Text.Heading 
        textColor={'gradient-to-b'} 
        className='from-primary-darker to-primary-light md:text-heading-xl lg:text-heading-2xl xl:text-display-sm mt-[10vh]'
      >
        Welkom bij Skip Scanner
      </Text.Heading>

      {/* Subtitle */}
      <Text.Paragraph className='mt-2'>
        Log nu in of maak een nieuw account aan.
      </Text.Paragraph>

      <div className="flex flex-1"></div>

      <div className="flex space-x-4 pb-[10vh]">

        <Link href={'/auth/register'}>
          <Button btnStyle={'primary-filled'}>Registreren</Button>
        </Link>

        <Link href={'/auth/login'}>
          <Button btnStyle={'primary'}>Inloggen</Button>
        </Link>
        
      </div>
    
    </Layout>
  )
}

export default Index